import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import { Wrapper } from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'
import {SEO, Listing, SectionHeader, Container, Row, Col, Navigation, Footer} from '../components'
import Img from "gatsby-image"
import TextLoop from "react-text-loop"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import LocalizedLink from '../components/LocalizedLink'


const params = {
  pagination: {
    el: '.swiper-bullets',
    clickable: true
  },
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    // when window width is >= 320px
    340: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    // when window width is >= 480px
    600: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    // when window width is >= 640px
    900: {
      slidesPerView: 3,
      spaceBetween: 40
    }
  }
}
const SwiperItem = styled.div`
  .logo img{
    margin-top: 10px;
    height: 52px!important;
    width: auto!important;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .text{
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 5px;
  }
`
const Form = styled.div`
  background: #f0f8ff;
  padding: 30px;
  margin-left: -50px;
  z-index: 2;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: -60% auto 0;
    width: 95%;
    padding: 20px;
  }
  form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -15px;
    h3 {
      padding: 0 15px 10px;
      font-size: 24px;
      flex: 1 1 100%;
    }
    label {
      padding: 0 15px; 
      font-size: 12px;
      font-weight: 600;
      &.half {
        flex: 1 1 50%;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          flex: 1 1 100%;
        }
      }
      &.full {
        flex: 1 1 100%;
      }
    }
    input, textarea {
      display: block;
    }
    input[type="checkbox"] {
      margin-right: 10px;
      vertical-align: inherit;
      display: inline-block;
    }
    input[type="text"] ,input[type="email"]{
      display: block;
      width: 100%;
      border: 1px solid #ccc;
      color: #000;
      font-size: 14px;
      line-height: 1.3;
      margin: 5px 0 15px;
      padding: 15px 10px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 13px;
        padding:  10px;
      }
    }
    .button {
      flex: 1 1 100%;
      text-align: right;
      padding: 0 15px;
      button {
        width: 100%;
        max-width: 150px;
        display: inline-block;
        background: #1e84cc;
        color: white;
        font-weight: 500;
        font-size: 16px;
        padding: 12px 8px;
        border: 0;
        transition: all 0.5s;
        cursor: pointer;
        margin: 5px;
        &:hover span {
          padding-right: 20px;
          &:after {
            opacity: 1;
            right: 0;
          }
        }
        span {
          cursor: pointer;
          display: inline-block;
          position: relative;
          transition: 0.5s;
          &:after {
            content: '\\00bb';
            font-family: sans-serif;
            font-size: 17px;
            position: absolute;
            opacity: 0;
            top: 0;
            right: -20px;
            transition: 0.5s;
          }
          
        }
        
      }
    }
    textarea {
      display: block;
      width: 100%;
      border: 1px solid #ccc;
      color: #000;
      font-size: 14px;
      line-height: 1.3;
      margin: 5px 0 20px;
      padding: 15px 10px;
    }
    .kapan {
      display: none;
    }
  }
`
const Hero = styled.div`
  background-color: ${props => props.theme.colors.greyLight};
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 80vh;
    width: 100%;
    max-width: 1280px;
    padding: 0 20px;
    margin: 0 auto;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex-direction: column;
      min-height: inherit;
    }
    .content {
      width: 600px;
      max-width: 100%;
      order: 1;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        order: 2;
      }
      h1 {
        font-size: 16px;
        line-height: 20px;
        span {
          display: block;
          font-size: 82px;
          line-height: 90px;
          margin: 10px 0;
          color: #000;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 32px;
            line-height: 48px;
          }
        }
      }
      .description {
        color: #828282;
        line-height: 1.5;
        .button {
          text-decoration: none;
          color: #828282;
          border-bottom: 2px solid #ebebeb;
          display: inline-block;
          margin-top: 10px;
          &:hover {
            border-bottom: 2px solid #828282;
          }
        }
      }
    }
    .image {
      width: 600px;
      max-width: 100%;
      margin-left: 30px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding: 20px 0;
      }
    }
  }
`
const Service = styled.div`
display: flex;
align-items: center;
@media (max-width: ${props => props.theme.breakpoints.s}) {
  flex-wrap: wrap;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: ${props => props.theme.breakpoints.xs}) {
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 0;
}
.text {
  flex: 1 0 50%;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 1 0 100%;
    padding: 60px 0px 10px;
  }
  .order {
    font-size: 82px;
    font-weight: 500;
    color: #f0f8ff;
    margin: 0;
    position: absolute;
    z-index: -2;
    top: 10px;
  }
  .icon {
    height: 42px;
    width: auto;
    margin: 0 auto;
    display: block;
  }
  .title {
    font-size: 30px;
    font-weight: 500;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 22px;
    }
  }
  .description {
    font-size: 20px;
    color: #818181;
    max-width: 400px;
    font-weight: 300;
    margin: 0;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 16px;
    }
  }
}
.clipper {
  flex: 1 0 50%;
  margin-right: -30px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 1 0 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    flex: 1 0 100%;
  }
  &.clip-0, &.clip-6 {
    -webkit-clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%);
    margin-left: auto;
  }
  &.clip-1, &.clip-7 {
    -webkit-clip-path: polygon(16% 0, 100% 0, 100% 100%, 25% 100%, 0% 60%, 5% 14%);
    clip-path: polygon(16% 0, 100% 0, 100% 100%, 25% 100%, 0% 60%, 5% 14%);
    margin-left: auto;
  }
  &.clip-2, &.clip-8 {
    -webkit-clip-path: polygon(25% 0%, 100% 0, 100% 50%, 100% 100%, 25% 100%, 0% 50%);
    clip-path: polygon(25% 0%, 100% 0, 100% 50%, 100% 100%, 25% 100%, 0% 50%);
    margin-left: auto;
  }
  &.clip-3, &.clip-9 {
    -webkit-clip-path: polygon(45% 0, 100% 0, 100% 100%, 54% 100%, 21% 100%, 24% 61%, 2% 35%, 24% 27%);
    clip-path: polygon(45% 0, 100% 0, 100% 100%, 54% 100%, 21% 100%, 24% 61%, 2% 35%, 24% 27%);
    margin-left: auto;
  }
  &.clip-4 {
    -webkit-clip-path: polygon(30% 0, 100% 0, 100% 60%, 100% 100%, 25% 100%, 0% 60%, 10% 20%);
    clip-path: polygon(30% 0, 100% 0, 100% 60%, 100% 100%, 25% 100%, 0% 60%, 10% 20%);
    margin-left: auto;
  }
  &.clip-5 {
    -webkit-clip-path: polygon(0% 41%, 21% 0%, 100% 0, 100% 100%, 50% 100%);
    clip-path: polygon(0% 41%, 21% 0%, 100% 0, 100% 100%, 50% 100%);
    margin-left: auto;
  }
}
`
const Quote = styled.div`
  background: white;
  display: flex;
  align-items: center;
  margin: 20px 20px 20px 0;
  position: relative;
  &:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
  }
  p {
    background: white;
    position: relative;
    font-weight: bold;
    padding: 15px;
    margin: 0;
    &:before {
      content: "\\201D";
      position: absolute;
      left: -20px;
      top: 5px;
      font-size: 42px;
      font-family: sans-serif;
      color: #247dbd;
    }
    span {
      display: block;
      font-weight: 300;
      margin: 10px 0 0;
      font-size: 12px;
    }
  }
`
const Tasks = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -8px 80px;
  border-top: 1px solid #efefef;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-wrap: wrap;
    border-top: none;
    margin: 0 -8px 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    flex-wrap: wrap;
  }
  .task {
    position: relative;
    flex: 1 0 20%;
    padding: 30px 12px 8px;
    text-align: center;
    border-right: 1px solid #efefef;
    position: relative;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex: 1 0 50%;
      padding: 15px;
    }
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      flex: 1 0 100%;
      padding: 10px;
    }
    &:last-child {
      border-right: none;
    }
    &:before  {
      content: "";
      width: 32px;
      height: 32px;
      border-radius: 50px;
      background: aliceblue;
      position: absolute;
      top: 20px;
      right: 0;
      bottom: 0;
      left: 40%;
      z-index: -1;
    }
    .icon {
      height: 42px;
      width: auto;
      margin: 0 auto;
      display: block;
    }
    .title {
      font-size: 16px;
      margin: 30px 0 10px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 16px;
      }
    }
    .description {
      font-size: 14px;
      line-height: 1.5;
      color: #818181;
      margin: 0;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 14px;
      }
    }
  }
`

const Index = ({ data: { homepage, services, clients}, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <>
      <SEO pathname={location.pathname} locale={locale} title={homepage.data.meta_title} desc={homepage.data.meta_description}/>
      <Navigation locale={locale} ></Navigation>
      <Hero>
        <div className="container">
          <div className="content">
            <h1 className="title">{i18n.section.hero.title}
            <span className="loop">
              <TextLoop interval={1500}>
                <div>{i18n.section.hero.loop.design}</div>
                <div>{i18n.section.hero.loop.build}</div>
                <div>{i18n.section.hero.loop.deploy}</div>
                <div>{i18n.section.hero.loop.scale}</div>
                <div>{i18n.section.hero.loop.migrate}</div>
              </TextLoop>
            </span>  
            </h1>
            <p className="description">{i18n.section.hero.description}<br/><Link to="#services" className="button">{i18n.section.hero.button}</Link></p>
          </div>
          <div className="image">
            <Img fluid={homepage.data.image.localFile.childImageSharp.fluid} alt={i18n.section.hero.title} title={i18n.section.hero.title}/>
          </div>
        </div>
      </Hero>
      <Wrapper scrollId="services"  >
        <Container id={website.skipNavId}>
          <Row>
            <Col>
              <SectionHeader 
                title={i18n.section.services.title}
                subtitle={i18n.section.services.subtitle}
                description={i18n.section.services.description} 
              />
            </Col>
          </Row>
          {services.edges[0].node.data.body.map((s, index) => (
            <Row key={index} data-name={`services-entry-${index}`}>
              <Col>
                  <Service  >
                    <div className="text">
                      <p className="order">{index}</p>
                    <h3 className="title">{s.primary.service_title}</h3>
                    <p className="description">{s.primary.service_description}</p>
                    </div>
                    <div className={`clipper clip-${index}`}>
                      <Img fluid={s.primary.service_image.localFile.childImageSharp.fluid} title={s.primary.service_title} alt={s.primary.service_title} />
                    </div>
                  </Service>
                  <Tasks>
                    {s.items.map((i, index) => (
                      <div className="task" key={index} data-name={`task-entry-${index}`}> 
                        <img loading="lazy" src={i.task_icon.url} alt={i.task_icon.alt} title={i.task_title} className="icon"/>
                        <h4 className="title">{i.task_title}</h4>
                        <p className="description">{i.task_description}</p>
                      </div>
                    ))}
                  </Tasks>
              </Col>
            </Row>
          ))}
        </Container>
      </Wrapper>
      <Wrapper scrollId="about" type="about">
        <Container>
          <Row>
            <Col>
            <SectionHeader title={i18n.section.about.title} subtitle={i18n.section.about.subtitle} description={i18n.section.about.description} />
            </Col>
          </Row>
        </Container>
      </Wrapper>
      {/* <Wrapper background="#f7f8fc" scrollId="clients">
        <Container>
          <Row>
            <Col>
            <SectionHeader title={i18n.section.clients.title} subtitle={i18n.section.clients.subtitle} description={i18n.section.clients.description} />
            </Col>
          </Row>
          <Row align="center" flow="wrap">
            <Col className="col-3">
              <Quote>
                <p>{i18n.page.index.clients.feedback.text}<span>{i18n.page.index.clients.feedback.client}</span></p>
              </Quote>
            </Col>
            <Col className="col-9">
              <Swiper {...params}>
                {clients.edges[0].node.data.body.map((i, index) => (
                  <SwiperItem key={index} data-name={`slide-entry-${index}`}>
                    <Img className="logo" fluid={i.primary.logo.localFile.childImageSharp.fluid} alt={i.primary.name} title={i.primary.name} />
                    <h3 className="name">{i.primary.name}</h3>
                    <p className="text">{i.primary.description}</p>
                  </SwiperItem>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </Wrapper> */}
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <SectionHeader title={i18n.section.contact.title} subtitle={i18n.section.contact.subtitle} description={i18n.section.contact.description} />
            </Col>
          </Row>
          <Row align="center" flow="wrap">
            <Col className="col-6">
              <img src="/images/contact.jpg"></img>
            </Col>
            <Col className="col-6">
              <Form>
              <form
                  name="contact"
                  method="post"
                  action="/thank-you"
                  data-netlify="true"
                  data-netlify-honeypot="kapan-field"
                >
                <input type="hidden" name="form-name" value="contact" />
                <h3>{i18n.forms.contact.title}</h3>
                  <label className="half"  htmlFor={'fullname'}>
                    {i18n.forms.contact.fullname}
                    <input 
                      className="input"
                      type={'text'}
                      name={'fullname'}
                      id={'fullname'}
                      required={true}
                    />
                  </label>
                  <p className="kapan">
                    <label><input name="kapan-field" /></label>
                  </p>
                  <label className="half" htmlFor={'email'}>
                    {i18n.forms.contact.email}
                    <input 
                        className="input"
                        type={'email'}
                        name={'email'}
                        id={'email'}
                        required={true}
                    />
                  </label>
                  <label className="half" htmlFor={'phone'}>
                    {i18n.forms.contact.phone}
                    <input
                        className="input"
                        type={'text'}
                        name={'phone'}
                        id={'phone'}
                        required={true}
                    />
                  </label>
                  <label className="half" htmlFor={'messcompanyage'}>                  
                    {i18n.forms.contact.company}
                    <input
                        className="input"
                        type={'text'}
                        name={'company'}
                        id={'company'}
                        required={true}
                    />
                  </label>
                  <label className="full" htmlFor={'message'}>
                    {i18n.forms.contact.message}
                    <textarea 
                      className="textarea"
                      name={'message'}
                      id={'message'}
                      required={true}
                    />
                  </label>
                  <label className="full">
                    <input 
                      className="input"
                      type={'checkbox'}
                      name={'consent'}
                      id={'consent'}
                      required={true}/>
                    {i18n.forms.contact.consent}
                  </label>
                  <div className="button">
                    <button type="submit"><span>{i18n.forms.contact.send}</span></button>
                  </div>
                </form>
              </Form>
            </Col>
          </Row>
        </Container>
    </Wrapper>
    <Footer locale={locale} />
    </>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired,
    clients: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      lang
      data {
        meta_title
        meta_description
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    services: allPrismicServices(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              items {
                task_title
                task_description
                task_icon {
                  url
                  alt
                }
              }
              primary {
                service_title
                service_description
                service_image {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    clients: allPrismicClients(sort: { fields: [last_publication_date], order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              primary {
                name
                description
                logo {
                  localFile {
                    childImageSharp {
                      fluid{
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
